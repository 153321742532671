import React, { FC, useEffect, useRef } from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Headline, LoadingPage, PrimaryButton, Reviews, Seo } from 'components';
import Header from 'components/Header';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { useRouter } from 'apis/history';
import { Analytics } from 'apis/Analytics';
import SeparatorIcon from 'assets/icons/separator.svg';
import { usePageView, useQuizData } from 'utils/hooks';
import { AppState } from 'state/types';

import AnswerResults from './components/AnswerResults';
import Help from './components/Help';
import MembersStatistics from './components/MembersStatistics';
import Description from './components/Description';
import CheckItem from 'components/CheckItem';
import IconList from './components/IconList';
import HorizontalList from './components/HorizontalList';
import AppActions from './components/AppActions';
import Promise from './components/Promise';
import StartNowButton from './components/StartNowButton';
import AppFeatures from './components/AppFeatures';
import CaseStudies from './components/CaseStudies';
import BannerSection from 'components/BannerSection';
import SliderBenefits from './components/SliderBenefits';
import CheckoutDescription from 'pages/start/checkout/components/Description';

import 'swiper/css';
import Consultation from './components/Consultation';
import SliderGraph from './components/SliderGraph';
import Container from 'pages/start/checkout/components/Container';
import ImageSection from 'pages/start/checkout/components/ImageSection';
import 'swiper/css/pagination';
import AnswerImages from './components/AnswerImages';
import GraphAndType from './components/GraphAndType';
import { ContentLoader } from 'components/others/ContentLoader';
import { searchForStringInRND } from 'utils/images';
import AdhdIntro from './components/AdhdIntro';
import AnswerBasedImages from './components/AnswerBasedImages';
import ListIconsWithCaption from './components/cards/ListIconsWithCaption';
import IconWithCaptionCardList from './components/cards/IconWithCaptionCardList';
import theme from 'utils/theme';
import EasterHeroSection from 'components/EasterHeroSection';
import OurPromise from './components/OurPromise';
import VerticalListWithImg from 'components/imageWithList/VerticalListWithImg';
import HorizontalListWithImg from 'components/imageWithList/ListWithImg';
import VerticalListWithCross from 'components/VerticalListWithCross';
import Tracking from 'utils/tracking';
import { normalizeStates } from 'utils/localization';

const Button = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})`
  padding: 0.625rem 1.5rem;
  min-width: 10.22rem;
  max-width: 15rem;
  @media ${mobile} {
    min-width: 7.06rem;
  }
`;

const Separator = styled(SeparatorIcon)`
  width: 100vw;
`;

const EvenList = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 3rem;
  @media ${tablet} {
    flex-direction: column;
    gap: 1.5rem;
  }
`;

const StartNow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem 1rem;
`;

const ColoredContainer = styled.div<{ $bgColor?: string; $padding?: string }>`
  background: ${({ $bgColor }) => $bgColor || theme.colors.light0};
  padding: ${({ $padding }) => $padding || 0};
`;

const LightBlueContainer = styled(ColoredContainer)`
  position: relative;
  background: ${theme.colors.lightBlue};
  // margin-bottom: 3rem;
`;
const LightBlueSquare = styled.div`
  position: absolute;
  height: 82px;
  width: 82px;
  background: ${theme.colors.beige};
  left: calc(50% - 41px);
  top: -41px;
  transform: rotate(45deg);
`;

const Results: FC<RouteComponentProps> = ({ location }) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;
  const user = useSelector((state: AppState) => state.user);

  const quizData = useQuizData('results');

  const { goToEmail, goToCheckout, goToOnBoarding } = useRouter();

  const { isMobile, isTablet } = useQuery();

  usePageView({
    client_code: user.code,
    city: encodeURIComponent(
      user.geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: user.geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      user.geolocation?.iso_country || '',
      user.geolocation?.iso_state || '',
    ),
    gender: user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : null,
    email: user.user?.email,
  });

  const handleContinue = () => {
    if (location?.pathname) {
      Tracking.trackCTAButton(location.pathname);
    }
    if (variant === 'email-first') {
      return window.location.search.includes('tst3')
        ? goToOnBoarding()
        : goToCheckout({ discount: false });
    }
    window.location.search.includes('tst3') ? goToOnBoarding() : goToEmail();
  };

  const renderSection = (item: any, index: number) => {
    const key = `${item.type}-${index}`;

    switch (item.type) {
      default: {
        return <React.Fragment key={key} />;
      }
      case 'basedOnMultiple': {
        return (
          <React.Fragment key={key}>
            <AnswerImages item={item} onClick={handleContinue} />
          </React.Fragment>
        );
      }
      case 'section1': {
        return (
          <React.Fragment key={key}>
            <AnswerResults onClick={handleContinue} {...item} />
          </React.Fragment>
        );
      }
      case 'ListIconsWithCaptionSection': {
        return (
          <React.Fragment key={key}>
            <ListIconsWithCaption
              verticalFromTablet={item?.verticalFromTablet}
              onClick={handleContinue}
              data={item}
            />
          </React.Fragment>
        );
      }
      case 'ListIconsWithCaptionSectionPaddingL': {
        return (
          <React.Fragment key={key}>
            <ListIconsWithCaption
              verticalFromTablet={item?.verticalFromTablet}
              onClick={handleContinue}
              paddingY={isTablet ? 3 : 4}
              data={item}
            />
          </React.Fragment>
        );
      }
      case 'ListIconsWithCaptionSectionPaddingM': {
        return (
          <React.Fragment key={key}>
            <ListIconsWithCaption
              verticalFromTablet={item?.verticalFromTablet}
              onClick={handleContinue}
              paddingY={isTablet ? 3 : 1.5}
              data={item}
            />
          </React.Fragment>
        );
      }

      case 'IconWithCaptionCardListSection': {
        return (
          <React.Fragment key={key}>
            <IconWithCaptionCardList
              data={item}
              bgColor="#423D81"
              textColor="light0"
            />
          </React.Fragment>
        );
      }
      case 'verticalListWithImage': {
        return (
          <LightBlueContainer key={key}>
            <VerticalListWithImg {...item} key={key} />
          </LightBlueContainer>
        );
      }
      case 'horizontalListWithImg': {
        return (
          <LightBlueContainer key={key}>
            <HorizontalListWithImg {...item} key={key} />
            <LightBlueSquare />
          </LightBlueContainer>
        );
      }
      case 'caseStudiesSection': {
        return (
          <CaseStudies key={key} onStartBtnClick={handleContinue} {...item} />
        );
      }
      case 'appFeaturesSection': {
        return <AppFeatures key={key} {...item} />;
      }
      case 'section2': {
        return (
          <React.Fragment key={key}>
            <MembersStatistics {...item} />
            <Separator />
          </React.Fragment>
        );
      }
      case 'consultation': {
        return <Consultation key={key} item={item} onClick={handleContinue} />;
      }
      case 'section3': {
        return (
          <React.Fragment key={key}>
            <Description
              title={item.title}
              onClick={handleContinue}
              startBtnTitle={item?.startBtnTitle}
              mobileSmTitle={item?.mobileSmTitle}
            >
              <EvenList>
                {item.list?.map((item: string, index: number) => (
                  <CheckItem key={`description-1-${index}`}>{item}</CheckItem>
                ))}
              </EvenList>
            </Description>
          </React.Fragment>
        );
      }
      case 'section4': {
        return (
          <Description key={key} title={item.title} subtitle={item.subtitle}>
            <IconList data={item.list} />
          </Description>
        );
      }
      case 'section5': {
        return <AppActions key={key} {...item} />;
      }
      case 'section6': {
        return (
          <Description
            key={key}
            title={item.title}
            subtitle={item.subtitle}
            mobileCenterTitle
            onClick={handleContinue}
            startBtnTitle={item?.startBtnTitle}
          >
            <HorizontalList data={item.list} />
          </Description>
        );
      }
      case 'section7': {
        return <Help key={key} title={item.title} list={item.list} />;
      }
      case 'weFeaturedIn': {
        return (
          <ColoredContainer
            key={key}
            $bgColor={theme.colors.beige}
            $padding={isTablet ? '2.25rem 1rem' : '3rem 1rem'}
          >
            <Promise {...item} />
          </ColoredContainer>
        );
      }
      case 'section8': {
        return <Promise key={key} {...item} />;
      }
      case 'scientificEvidenceSection': {
        return <BannerSection key={key} {...item} />;
      }
      case 'sliderBenefits': {
        return <SliderBenefits key={key} {...item} onClick={handleContinue} />;
      }
      case 'sliderGraph': {
        return <SliderGraph key={key} {...item} />;
      }
      case 'appBenefitsSection': {
        return (
          <Container key={key} top={4} bottom={4}>
            <CheckoutDescription {...item} onClick={handleContinue} />
          </Container>
        );
      }
      case 'ourPromise': {
        return <OurPromise key={key} {...item} />;
      }
      case 'reviewsSection': {
        return <Reviews key={key} {...item} />;
      }
      case 'imageSection': {
        return <ImageSection key={key} {...item} />;
      }
      case 'graphAndTypeSection': {
        return <GraphAndType key={key} {...item} onClick={handleContinue} />;
      }
      case 'adhdIntro': {
        return <AdhdIntro key={key} {...item} onClick={handleContinue} />;
      }
      case 'answerBasedImages': {
        return (
          <AnswerBasedImages key={key} {...item} onClick={handleContinue} />
        );
      }
      case 'verticalListWithCross': {
        return <VerticalListWithCross {...item} key={key} />;
      }
    }
  };

  if (!quizData) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Header logoVariant="center" color="light0" />
      {!!quizData?.headlineTitle && (
        <Headline
          bodyText={quizData.headlineTitle}
          bgColor={quizData.headlineBgColor}
        />
      )}

      {quizData?.sections?.map(renderSection)}
      <StartNow ref={bottomRef}>
        <StartNowButton onClick={handleContinue}>
          {quizData?.navBarBtnTitle}
        </StartNowButton>
      </StartNow>
    </>
  );
};

export default Results;
