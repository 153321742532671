import React, { FC } from 'react';
import styled from 'styled-components';
import Text from './Text';
import CrossIcon from 'assets/icons/cross-icon.svg';

interface VerticalListWithCrossProps {
  title: string;
  list: string[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 1rem;
`;

const Title = styled(Text)`
  font-size: 22px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: -0.016em;
  text-align: center;
  margin-bottom: 0.5rem;
`;

const Item = styled(Text)`
  font-size: 1rem;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-left: 0.75rem;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 18rem;
  width: 100%;
  margin-top: 1rem;
`;

const VerticalListWithCross: FC<VerticalListWithCrossProps> = ({
  title,
  list,
}) => {
  const renderList = (item: string, index: number) => (
    <ItemContainer key={index}>
      <CrossIcon fill="#F87666" />
      <Item>{item}</Item>
    </ItemContainer>
  );
  return (
    <Container>
      <Title>{title}</Title>
      {list.map(renderList)}
    </Container>
  );
};

export default VerticalListWithCross;
